import styled from '@emotion/styled'

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  left: ${props => props.type === 'gt' ? '-150px' : 0};

  .dust {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
  }

  animation: animateAway 4s forwards;

  @keyframes animateAway {
    0% {
      opacity: 1;
      top: 0;
    }
    100% {
      opacity: 0;
      top: -150px;
    }
  }
`;

export const Prize = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 50px;
  margin-top: 50px;
  font-size: 30pt;

  .prize {
    color: #ffa55a;
    font-size: 30pt;
    font-family: Roboto;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-weight: bold;
  }

  span.plus {
    color: #ffa55a;
    font-family: Roboto;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-weight: bold;
    font-size: 20pt;
  }
`;